<template>
  <form-caution-driver-list :is-confirmed-page="true" />
</template>
<script>
import FormCautionDriverList from '@/components/FormComponents/CautionDriverList'
export default {
  components: {
    FormCautionDriverList,
  },
}
</script>
